export const ADDRESS = [
    {name :'National Highway 45, Potheri, SRM Nagar, Kattankulathur, Tamil Nadu, 603203' }
];

export const NAME = [
    {name:'Muthu Kiruba.B' , link:"#"},
    {name:'Maanasa.R', link:"#"}
];

export const EMAIL = [
    {name: 'muthukiruba27@gmail.com', link:"#"},
    {name: 'maanasaramamurthy@gmail.com', link:"#"}
]

export const PHONENUMBER = [
    {name:'9444859806', link:"#"},
    {name:'9962844176', link:"#"}
]